<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <preparacion />
      </v-col>

      <v-col cols="6">
        <oportunidades />
        <pedidos />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import preparacion from '@/views/crmpro/preparacion/preparacion.vue'
import oportunidades from '@/views/crmpro/preparacion/oportunidades.vue'
import pedidos from '@/views/crmpro/preparacion/pedidos.vue'
export default {
  components: {
    preparacion,
    oportunidades,
    pedidos
  },
}
</script>

<style>

</style>