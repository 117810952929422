<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card elevation="10">
          <v-toolbar
            height="40"
            dense
            flat
            color="blue"
            dark
          >
            <h3>Preparacion de la visita o llamada</h3>
          </v-toolbar>
          <v-col>
            <v-text-field
              dense
              label="Dias desde la ultima visita"
              hide-details
            />
            <v-text-field
              label="Dias desde la ultima compra"
              hide-details
            />
            <v-text-field
              label="Potencial de compra"
              hide-details
            />
            <v-text-field
              label="Objetivo para este mes"
              hide-details
            />
            <v-text-field
              label="Compra promedio mensual de este año"
              hide-details
            />
            <v-text-field
              label="Compras en este mes"
              hide-details
            />
            <v-text-field
              label="Cuentas por cobrar vencidas"
              hide-details
            />
            <v-text-field
              label="Estatus crediticio"
              hide-details
            />
            <v-text-field
              label="Pendientes por resolver"
              hide-details
            />
            <v-text-field
              label="Pedidos por entregar"
              hide-details
            />
            <v-text-field
              label="Cotizaciones por resolver"
              hide-details
            />
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      proyectos: [


      ],
      headers: [

        { text: 'Factura', value: 'factura'},
        { text: 'Fecha', value: 'fecha' },
        { text: 'Saldo', value: 'saldo' },
  
      ],


      
      // json_fields: {
      //   Estatus: "completo",
      //   Id: "idproyecto",
      //   FechaInicio: "inicio",
      //   FechaFin: "meta",

      //   NombreProyecto: "nomproy",
      //   NumCliente: "numcli",
      //   NumProyecto: "numproy",
      //   Observaciones: "obs",
      //   Precio: "precio",
      // },
      // json_meta: [
      //   [
      //     {
      //       key: "charset",
      //       value: "utf-8",
      //     },
      //   ],
      // ],
      // json_data: [],
    }
  },
}
</script>